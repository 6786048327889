.contact-block-4 {
    padding: 60px 15px 0;

    @include media('>=desktop') {
        padding: 66px 40px;
    }
}

.contact-block-4-content {
    @include media('>=desktop') {
        display: flex;
        flex-direction: row;
    }
}

.contact-block-4-title {
    text-align: center;
    margin: 0 0 37px;

    @include media('>=desktop') {
        margin: 0 0 50px;
    }
}

.contact-block-4-text  {
    text-align: center;
    margin: 40px 0;

    @include media('>=desktop') {
        flex: 0 0 325px;
        margin: 80px 40px 20px 0;
    }
}

.contact-block-4-img-wrapper {
    @include center-image;

    @include media('>=desktop') {
        justify-content: flex-end;
        flex-grow: 1;
    }
}

.contact-block-4-desc-title-1 {
    font-size: 35px;
    line-height: 40px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    margin: 0 0 38px;
}

.contact-block-4-desc-subtitle {
    font-family: $GranvilleLight;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 7px;
}

.contact-block-4-desc-title-2 {
    font-size: 35px;
    line-height: 50px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    margin: 0 0 11px;
}

.contact-block-4-desc-text {
    font-family: $GaramondItalic;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.95px;
    margin: 0 0 30px;
}
