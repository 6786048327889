.lp-block-2 {
    overflow: hidden;
    margin: 45px 0 37px;

    @include media('>=tablet') {
        margin: 45px 0 37px;
    }

    @include media('>=desktop') {
        margin: 45px auto;
        overflow: visible;
    }

    @include media('>=desktopLarge') {
        margin: 75px auto 45px;
    }
}

.lp-block-2-images-block {
    padding: 0 15px;

    @include media('>=tablet') {
        padding: 0 40px;
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
}

.lp-block-2-images-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.lp-block-2-images-block-left {
    margin: 0 0 120px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.lp-block-2-images-block-right {
    padding: 0 35px 40px;

    @include media('>=desktop') {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 100px;
    }
}

.lp-block-2-img-1,
.lp-block-2-img-2 {
    margin: 0 auto;
}

.lp-block-2-img-1-wrapper,
.lp-block-2-img-2-wrapper {
    position: relative;
}

.lp-block-2-img-1-decor {
    position: absolute;
    right: -70px;
    bottom: -80px;

    @include media('>=desktopLarge') {
        right: -150px;
        bottom: -150px;
    }
}

.lp-block-2-img-2-decor {
    position: absolute;
    top: -100px;
    left: -50px;

    @include media('>=desktopLarge') {
        top: -120px;
        left: -40px;
    }
}

.lp-block-2-decor {
    width: 100%;
}

.lp-block-2-subtitle {
    text-align: center;
    font-family: $GaramondItalic;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.95px;

    &.top-padding {
        padding: 40px 0 0;

        @include media('>=desktop') {
            padding: 46px 0 0;
        }
    }
}

.lp-block-2-text-block {
    padding: 0 15px;

    @include media('>=tablet') {
        padding: 0 40px;
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        margin: 120px 0 0;
    }

    @include media('>=desktopLarge') {
        margin: 195px 0 0;
    }
}

.lp-block-2-text-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.lp-block-2-title {
    padding: 0 0 17px;
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 1.4px;

    @include media('>=tablet') {
        padding: 0 40px 0 70px;
    }

    @include media('>=desktop') {
        padding: 0 58px 0 94px;
        text-align: left;
        font-size: 36px;
        line-height: 50px;
        letter-spacing: 1.8px;
    }

    .title-rouge {
        color: $rounge;
    }
}

.lp-block-2-desc {
    text-align: center;

    @include media('>=desktop') {
        padding: 0 40px 0 0;
        text-align: left;
    }
}

.lp-block-2-link-wrapper {
    margin: 20px 0 0;
    text-align: center;
}
