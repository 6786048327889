.privatisation-block-3 {
    overflow: hidden;

    @include media('>=desktop') {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    &.desktop-blocks-gap {
        @include media('>=desktop') {
            gap: 10px;
        }
    }
}

.privatisation-block-3-tile {
    @include media('>=desktop') {
        display: flex;
        flex-direction: column;
        margin: 0 0 30px;
    }
}

.privatisation-block-3-tile-content {
    position: relative;
    background-color: $white;
    padding: 54px 35px;
    text-align: center;

    @include media('>=desktop') {
        max-width: 350px;
        padding: 25px 24px 35px;
        margin: -150px auto 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}

.privatisation-block-3-tile-title {
    font-size: 35px;
    line-height: 40px;
    letter-spacing: 1.75px;
    margin: 0 0 15px;

    @include media('>=desktop') {
        font-size: 36px;
        line-height: 50px; 
        letter-spacing: 1.8px;
        margin: 0 0 22px;
    }
}

.privatisation-block-3-tile-desc {
    margin: 0 0 17px;

    @include media('>=desktop') {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1px;
        margin: 0 0 23px;
    }
}

.privatisation-block-3-tile-content-1 {
    .privatisation-block-3-icon-wrapper {
        position: absolute;
        top: -50px;
        right: 2px;

        @include media('>=desktop') {
            right: -70px;
            top: -70px;
        }
    }
}

.privatisation-block-3-tile-content-2 {
    .privatisation-block-3-icon-wrapper {
        position: absolute;
        top: -70px;
        right: 12px;

        @include media('>=desktop') {
            top: -65px;
            right: -75px;
        }
    }
}

.privatisation-block-3-tile-link-wrapper {
    @include media('>=desktop') {
        margin: auto 0 0;
    }
}
