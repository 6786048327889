.privatisation-block-4-carousel:not(.slick-initialized) {
    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
    }

    .privatisation-block-4-carousel-tile {
        @include media('>=tablet') {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include media('>=desktop') {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }
}

.privatisation-block-4-carousel.slick-initialized {
    .privatisation-block-4-carousel-tile {
        width: 90vw;

        @include media('>=tablet') {
            width: calc(100vw / 2.2)
        }

        @include media('>=desktop') {
            width: calc(100vw / 2.5);
        }

        @include media('>=desktopLarge') {
            width: calc(1358px / 2.5);
        }
    }
}

.privatisation-block-4-mobile-tiles-gap {
    @include media('<tablet') {
        margin: 0 -3px;
    }

    .privatisation-block-4-carousel-tile {
        @include media('<tablet') {
            padding: 0 3px;
        }
    }
}

.privatisation-block-4-tablet-tiles-gap {
    @include media('>=tablet', '<desktop') {
        margin: 0 -5px;
    }

    .privatisation-block-4-carousel-tile {
        @include media('>=tablet', '<desktop') {
            padding: 0 5px;
        }
    }
}

.privatisation-block-4-desktop-tiles-gap {
    @include media('>=desktop') {
        margin: 0 -5px;
    }

    .privatisation-block-4-carousel-tile {
        @include media('>=desktop') {
            padding: 0 5px;
        }
    }
}
