.privatisation-block-5 {
    padding: 45px 15px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 45px 30px;
    }

    @include media('>=desktopLarge') {
        padding: 45px 95px;
    }
}

.privatisation-block-5-content {
    margin: 0 0 40px;

    @include media('>=desktop') {
        margin: 0;
        flex-grow: 1;
        padding: 0 20px 0 0;
    }

    @include media('>=desktopLarge') {
        padding: 0 30px 0 0;
    }
}

.privatisation-block-5-title {
    text-align: center;
    margin: 0 0 37px;

    @include media('>=desktop') {
        margin: 0 0 50px;
    }
}

.privatisation-block-5-desc {
    text-align: center;

    @include media('>=desktop') {
        text-align: left;
    }
}

.privatisation-block-5-img-wrapper {
    position: relative;
}

.privatisation-block-5-img-decor {
    position: absolute;
    right: 0;
    bottom: -50px;

    @include media('>=desktop') {
        right: -30px;
        bottom: -120px;
    }

    @include media('>=desktopLarge') {
        right: -60px;
    }
}

.privatisation-block-5-desc-row {
    margin: 0 0 27px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        margin: 0 0 35px;
    }
}

.privatisation-block-5-desc-title-wrapper {
    @include media('>=desktop') {
        flex: 0 0 30%;
        max-width: 30%;
        padding: 0 10px 0 0;
    }
}

.privatisation-block-5-desc-title {
    margin: 0 0 20px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.privatisation-block-5-desc-text {
    @include media('>=desktop') {
        flex-grow: 1;
    }
}
