.beach-subtitle {
    font-family: $GaramondItalic;
    font-size: 20px;
    text-transform: none;
    margin: 20px 0 0;
    color: $glacier;

    @include media('>=desktop') {
        font-size: 24px;
        margin: 35px 0 0;
    }
}

.beach-link {
    display: inline-block;
    font-family: $GranvilleLight;
    text-transform: uppercase;
    font-size: 40px;
    line-height: normal;
    color: $white;
    padding: 0 0 10px;
    margin: 0 0 30px;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    @include media('>=desktop') {
        font-size: 60px;
        padding: 0 0 10px;
        margin: 0 0 26px;
    }

    @include media('>=desktopLarge') {
        font-size: 75px;
        padding: 0 0 16px;
        margin: 0 0 30px;
    }

    &:active,
    &:focus,
    &:hover {
        color: $glacier;
        border-color: $glacier;
        text-decoration: none;
    }
}

.beach-img-content {
    margin: 0 0 30px;

    @include media('>=desktop') {
        margin: 0 0 50px;
    }
}
