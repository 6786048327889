.reservation-block-1 {
    .pd-rounge-header {
        margin: 50px 15px 0;
    }

    &.pd-rounge-content {
        @include media('>=desktop') {
            margin: 55px auto 0;
        }
    }
}
