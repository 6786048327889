.restaurant-block-4-header {
    padding: 53px 15px 38px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        padding: 70px 68px 55px;
    }
}

.restaurant-block-4-header-col {
    text-align: center;

    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 20px;
        text-align: left;
    }
}

.restaurant-block-4-title {
    font-size: 35px;
    line-height: 40px;
    letter-spacing: 1.75px;
    margin: 0 0 24px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.restaurant-block-4-links {
    margin: 20px 0 0;
    text-align: center;

    @include media('>=desktop') {
        display: flex;
        justify-content: center;

    }
}

.restaurant-block-4-links-gap {
    .restaurant-block-4-action-wrapper:first-child {
        @include media('>=desktop') {
            padding: 0 48px 0 0;
        }
    }
}

.restaurant-block-4-action-wrapper {
    margin: 10px 0 0;
}

.restaurant-block-4-menu {
    background-color: $glacier;
    padding: 35px 15px 45px;

    @include media('>=desktop') {
        padding: 60px 20px;
    }
}

.restaurant-block-4-course {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
    }
}

.restaurant-block-4-course-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 20px;
    }
}

.restaurant-block-4-course-title {
    font-family: $GranvilleLight;
    color: $rounge;
    font-size: 28px;
    line-height: 31px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin: 0 0 20px;

    @include media('>=desktop') {
        font-size: 30px;
        line-height: 31px;
        letter-spacing: 1.5px;
        margin: 0 0 30px;
    }
}

.restaurant-block-4-course-item {
    margin: 0 0 40px;

    @include media('>=desktop') {
        margin: 0 0 65px;
    }
}

.restaurant-block-4-course-list {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1px;

    @include media('>=desktop') {
        font-size: 20px;
        line-height: 30px;
    }

    dd {
        white-space: nowrap;
    }

    dd::after {
        padding: 0 0 15px;

        @include media('>=desktop') {
            padding: 0 0 22px;
        }
    }
}

.restaurant-block-4-course-dish {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1px;

    @include media('>=desktop') {
        font-size: 20px;
        line-height: 30px;
    }

    margin: 0 0 15px;

    @include media('>=desktop') {
        margin: 0 0 22px;
    }
}

.restaurant-block-4-menu-link-wrapper {
    text-align: center;
}
