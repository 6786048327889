// =================================
//    Footer
// =================================

.prefooter {
    padding: 50px 15px 40px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 75px 10px;
    }

    @include media('>=desktopLarge') {
        padding: 75px 20px;
    }
}

.prefooter-col-text {
    text-align: center;
    padding: 0 0 65px;

    @include media('>=desktop') {
        flex-grow: 1;
        padding: 0;
    }
}

.prefooter-body {
    @include media('>=desktop') {
        text-align: left;
    }
}

.prefooter-body-col-title {
    @include media('>=desktop') {
        flex: 0 0 200px;
        max-width: 200px;
        padding: 0 20px 0 40px;
    }

    @include media('>=desktopLarge') {
        flex: 0 0 300px;
        max-width: 300px;
        padding: 0 150px 0 60px;
    }
}

.prefooter-body-col-content {
    @include media('>=desktop') {
        padding: 0 20px 0 0;
        line-height: 31px;
    }
}

.prefooter-col-image {
    @include media('>=desktop') {
        flex: 0 0 calc(40% + 30px);
        max-width: calc(40% + 30px);
        padding: 0 30px 0 0;
    }

    @include media('>=desktopLarge') {
        flex: 0 0 580px;
        max-width: 580px;
        padding: 0 60px 0 0;
    }
}

.prefooter-title {
    margin: 0 0 24px;

    @include media('>=desktop') {
        margin: 0 0 50px;
    }

    @include media('>=desktopLarge') {
        margin: 0 0 65px;
    }
}

.prefooter-body-row {
    margin: 0 0 25px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
    }
}

.prefooter-body-subtitle {
    margin: 0 0 16px;
}

.refooter-body-list {
    font-family: $GaramondRegular;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 1.1px;

    @include media('>=desktop') {
        line-height: inherit;
    }
}

.footer {
    margin: 0 15px;

    @include media('>=desktop') {
        margin: 0 20px;
    }

    @include media('>=desktopLarge') {
        margin: 0 44px;
    }
}

.footer-wrapper {
    border-top: 1px solid $marine;
    padding: 50px 0;
}

.footer-row {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
    }
}

.footer-row-last {
    @include media('<desktop') {
        display: flex;
        flex-direction: column;
    }
}

.footer-col-logo {
    @include media('<desktop') {
        order: 3;
    }
}

.footer-col {
    text-align: center;
    margin: 0 0 50px;

    @include media('>=desktop') {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

.footer-title {
    font-family: $GaramondRegular;
    font-size: 22px;
    line-height: 26px;
    text-transform: none;
}

.footer-desc {
    font-family: $GaramondItalic;
    font-size: 22px;
    line-height: 26px;
}

.footer-tel-link,
.footer-mail-link {
    text-decoration: none;
    color: $marine;
}

.footer-logo-link {
    display: inline-block;
}

.footer-social {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.footer-social-title {
    margin: 0 0 20px;
}

.footer-social-link {
    margin: 0 16px 0 0;

    &:last-child {
        margin: 0;
    }
}

.footer-bottom-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    text-align: center;
    font-family: $GaramondRegular;
    font-size: 17px;
    line-height: 20px;
    padding: 0 0 26px;

    @include media('>=desktop') {
        padding: 0 0 40px;
    }
}

.footer-bottom-link {
    display: inline-block;
}

.footer-bottom-link-li {
    display: flex;

    &::after {
        content: '—';
        display: inline-block;
        padding: 0 5px;
    }

    &:last-child::after {
        content: none;
    }
}

.newsletter-title {
    text-align: center;
    font-size: 22px;
    line-height: 22px;
    margin: 0 0 23px;
}

.newsletter-fieldset {
    @include media('>=desktop') {
        max-width: 310px;
        margin: 0 auto;
    }
}

.newsletter-consent {
    font-size: 16px;
    line-height: 20px;
    margin: 0 15px;

    a {
        text-decoration-line: underline;
    }

    @include media('>=desktop') {
        margin: 0;
    }
}

.newsletter-row {
    max-width: 270px;
    margin: 0 auto 5px;

    @include media('>=desktop') {
        margin: 0;
        max-width: initial;
    }
}
