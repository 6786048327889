// =================================
//    Retina
// =================================
@mixin retina($image, $showWidth: true, $showHeight: true) {
    @media (min-resolution: 192dpi), (min-resolution: 2dppx) {
        @content;
    }
}

// =================================
//    Icons
// =================================
@mixin mobile-first-icon($image, $hover: '') {
    display: block;
    @include sprite($image);

    @include media('retina2x') {
        @include sprite("#{$image}#{$sprite-suffix}");
        transform-origin: top left;
        transform: scale(0.5);

        * {
            transform: scale(2);
        }
    }
}

// =================================
//    Absolute center
// =================================
@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-horizont-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin absolute-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// =================================
//    Center image
// =================================
@mixin center-image {
    display: flex;
    justify-content: center;
}

// =================================
//    Carousel controls: arrows
// =================================
@mixin carousel-arrow($direction: 'next') {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto;
    border: 0;
    background-color: transparent;
    padding: 10px;
    box-sizing: content-box;
    text-align: center;

    &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid $marine;
        border-width: 1px 0 0 1px;

        @if $direction == 'next' {
            transform: rotate(-225deg);
        } @else if $direction == 'prev' {
            transform: rotate(-45deg);
        } @else if $direction == 'up' {
            transform: rotate(45deg);
        } @else if $direction == 'down' {
            transform: rotate(225deg);
        }
    }
}
