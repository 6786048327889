// Base style for slick slider

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slick-track,
    .slick-list {
        transform: translate3d(0, 0, 0);
    }
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
    }
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &::before,
    &::after {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow {
    font-size: 0;

    .slick-hidden {
        display: none;
    }
}

// =================================
//    slick slider arrows style override
// =================================
.slick-prev {
    @include carousel-arrow('prev');

    .slick-vertical & {
        @include carousel-arrow('up');
    }
}

.slick-next {
    @include carousel-arrow('next');

    .slick-vertical & {
        @include carousel-arrow('down');
    }
}

.slick-arrows-below-mobile {
    .slick-list {
        @include media('<desktop') {
            padding-bottom: 70px !important;
        }
    }

    .slick-arrow {
        @include media('<desktop') {
            position: absolute;
            bottom: 0;
            z-index: 1;
        }
    }

    .slick-prev {
        @include media('<desktop') {
            left: calc(50% - 50px);
        }
    }

    .slick-next {
        @include media('<desktop') {
            right: calc(50% - 50px);
        }
    }
}

.slick-arrows-side-desktop {
    .slick-arrow {
        @include media('>=desktop') {
            @include absolute-vertical-center;
            z-index: 1;
        }
    }

    .slick-prev {
        @include media('>=desktop') {
            left: 40px;
        }
    }

    .slick-next {
        @include media('>=desktop') {
            right: 40px;
        }
    }
}

// =================================
//    slick slider dots style override
// =================================
.slick-dots {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 30px 0 0;

    li {
        margin: 0 10px 0 0;
        width: 10px;
        min-width: 10px;
        height: 10px;
        border: 1px solid $marine;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;

        &.slick-active {
            background-color: $marine;
        }

        &:last-child {
            margin: 0;
        }
    }

    button {
        border: 0;
        background-color: transparent;
        padding: 0;
        color: transparent;
        outline: none;
    }
}

.slick-white-dots {
    .slick-dots li {
        border-color: $white;

        &.slick-active {
            background-color: $white;
        }
    }
}

.slick-dots-above {
    .slick-dots {
        position: absolute;
        bottom: 25px;
        margin: 0;
    }
}
