.gallery-block-2 {
    overflow: hidden;
}

.gallery-block-2-row {
    padding: 0 15px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
    }
}

.gallery-block-2-row-1 {
    @include media('>=desktop') {
        align-items: center;
        padding: 57px 0;
    }
}

.gallery-block-2-row-2 {
    @include media('>=desktop') {
        padding: 0 0 115px;
    }
}

.gallery-block-2-row-3 {
    @include media('>=desktop') {
        padding: 0 0 95px;
    }
}

.gallery-block-2-left {
    text-align: center;

    @include media('>=desktop') {
        width: 55%;
        max-width: 55%;
    }
}

.gallery-block-2-row-1-left {
    padding: 50px 20px;

    @include media('>=desktop') {
        padding: 20px 80px;
    }
}

.gallery-block-2-right {
    @include media('>=desktop') {
        flex-grow: 1;
    }
}

.gallery-block-2-row-2-right {
    text-align: center;
}

.gallery-block-2-img-col {
    text-align: center;
    padding: 0 35px;

    @include media('>=desktop') {
        text-align: left;
        padding: 0;
    }
}

.gallery-block-2-title {
    color: $rounge;
    margin: 0 0 22px;
}

.gallery-block-2-desc {
    font-family: $GaramondItalic;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.95px;
    text-align: center;

    @include media('>=desktop') {
        font-size: 30px;
        line-height: 35px;
    }
}

.gallery-block-2-row-2-desc {
    padding: 50px 20px;

    @include media('>=desktop') {
        padding: 80px;
    }
}

.gallery-block-2-row-2-desc-2 {
    padding: 50px 20px 70px;
}

.gallery-block-2-image-wrapper {
    display: inline-block;
    position: relative;
}

.gallery-block-2-row-2-image-wrapper-1 {
    text-align: center;

    @include media('>=desktop') {
        padding: 0 60px;
        margin: -100px 0 0;
    }
}

.gallery-block-2-row-2-image-wrapper-2 {
    text-align: center;
    margin: 0 35px;

    @include media('>=desktop') {
        margin: 90px 0 0;
    }
}

.gallery-block-2-icon-wrapper {
    position: absolute;
}

.gallery-block-2-row-1-icon-wrapper {
    right: -40px;
    bottom: -40px;
}

.gallery-block-2-row-2-icon-wrapper-1 {
    top: 0;
    right: 0;
}

.gallery-block-2-row-2-icon-wrapper-2 {
    top: -60px;
    right: -30px;
}

// gallery block 2 - row 3
.gallery-block-2-row-3-left {
    padding: 70px 0 25px;
    text-align: center;

    @include media('>=desktop') {
        padding: 0 25px 0 100px;
    }

    @include media('>=desktopLarge') {
        padding: 0 50px 0 200px;
    }
}

.gallery-block-2-row-3-right {
    padding: 25px 0 70px;
    text-align: center;

    @include media('>=desktop') {
        padding: 350px 50px 0 25px;
    }

    @include media('>=desktopLarge') {
        padding: 300px 90px 0 50px;
    }
}

.gallery-block-2-row-3-image-wrapper {
    position: relative;
    display: inline-block;
}

.gallery-block-2-row-3-icon-wrapper {
    position: absolute;
    top: -30px;
    right: -10px;

    @include media('>=desktop') {
        top: -90px;
        right: -140px;
    }
}

// gallery block 2 - row 4
.gallery-block-2-row-4-desc {
    padding: 47px 0 43px;
    text-align: center;
    font-family: $GaramondItalic;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.95px;

    @include media('>=desktop') {
        font-size: 30px;
        line-height: 35px;
        max-width: 50%;
        margin: 0 auto;
        padding: 65px 0;
    }
}

.gallery-block-2-row-4 {
    @include media('>=desktop') {
        justify-content: center;
    }
}

.gallery-block-2-row-4-left {
    padding: 0 0 55px;
}

.gallery-block-2-row-4-col {
    text-align: center;

    @include media('>=desktop') {
        padding: 0 60px;
    }
}

.gallery-block-2-row-4-image-wrapper {
    position: relative;
    display: inline-block;
}

.gallery-block-2-row-4-icon-wrapper-1 {
    @include media('>=desktop') {
        position: absolute;
        transform: scaleX(-1);
        top: -120px;
        left: -120px;
    }
}

.gallery-block-2-row-4-icon-wrapper-2 {
    @include media('>=desktop') {
        position: absolute;
        transform: scale(-1, -1) rotate(60deg);
        bottom: -120px;
        right: -135px;
    }
}
