.lp-block-4 {
    overflow: hidden;

    @include media('>=desktop') {
        padding: 80px 0;
    }
}

.lp-block-4-images-block {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 0 auto;
        max-width: 1082px;
    }
}

.lp-block-4-images-col {
    text-align: center;
    padding: 42px 50px;

    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 64px;
    }
}

.lp-block-4-img-wrapper {
    position: relative;
    display: inline-block;
}

.lp-block-4-img-1-decor {
    position: absolute;
    width: 76px;
    height: auto;
    left: -40px;
    bottom: -50px;

    @include media('>=desktopLarge') {
        width: 120px;
        height: auto;
        left: -50px;
        bottom: -70px;
    }
}

.lp-block-4-img-2-decor {
    position: absolute;
    width: 200px;
    height: auto;
    top: -50px;
    right: -80px;

    @include media('>=desktopLarge') {
        width: 413px;
        height: auto;
        top: -50px;
        right: -170px;
    }
}

.lp-block-4-link-wrapper {
    text-align: center;
    margin: 0 20px 50px;

    @include media('>=desktop') {
        margin: 20px 20px 0;
    }
}

.lp-block-4-link {
    font-size: 25px;
    line-height: 44px;
    letter-spacing: 1.25px;

    @include media('>=desktop') {
        text-align: center;
        font-size: 36px;
        line-height: 60px;
        letter-spacing: 1.8px;
    }
}
