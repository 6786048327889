.privatisation-block-7 {
    background-color: $glacier;
    padding: 80px 40px;
    text-align: center;

    @include media('>=desktop') {
        padding: 85px 50px;
    }
}

.privatisation-block-7-logos {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    margin: 0 -40px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.privatisation-block-7-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 40px;
    min-height: 65px;
    margin: 5px 0;

    @include media('>=tablet') {
        flex: 0 0 25%;
        max-width: 25%;
    }

    @include media('>=desktop') {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 50px;
        min-height: 85px;
        margin: 10px 0;
    }
}

.privatisation-block-7-title {
    font-size: 35px;
    line-height: 40px;
    letter-spacing: 1.75px;
    margin: 0 0 55px;

    @include media('>=desktop') {
        margin: 0 0 60px;
    }
}

.privatisation-block-7-link-wrapper {
    margin: 50px 0 0;
}
