.hp-block-3 {
    position: relative;
    margin: 72px 0 55px;

    @include media('>=desktop') {
        margin: 85px auto 60px;
    }
}

.hp-block-3-bg-content {
    position: relative;
}

.hp-block-3-bottom-content {
    font-family: $GaramondItalic;
    font-size: 19px;
    line-height: 25px;
    background-color: $glacier;
    padding: 35px 30px;

    @include media('>=desktop') {
        font-size: 30px;
        line-height: 35px;
        padding: 65px 35px;
    }
}

.hp-block-3-desc {
    max-width: 310px;
    margin: 0 auto;
    text-align: center;

    @include media('>=desktop') {
        max-width: 785px;
    }
}

.hp-block-3-carousel-wrapper {
    position: absolute;
    top: 50%;
    width: 70%;
    height: 50%;
    text-align: center;
    margin: 0 15%;

    @include media('>=tablet') {
        width: 34%;
        margin: 0 33%;
        height: 46%;
    }
}

.hp-block-3-carousel {
    height: 100%;

    .slick-dots {
        bottom: 0;
        position: absolute;
    }
}

.hp-block-3-circle-text-wrapper {
    @include circle-text('-hp', 'false');
}
