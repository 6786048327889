.reservation-block-2 {
    overflow: hidden;

    @include media('>=desktop') {
        padding: 80px 0;
    }
}

.reservation-block-2-images-block {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 0 auto;
        max-width: 1082px;
    }
}

.reservation-block-2-images-col {
    text-align: center;
    padding: 42px 50px;

    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 64px;
    }
}

.reservation-block-2-img-wrapper {
    position: relative;
    display: inline-block;
}

.reservation-block-2-img-1-decor {
    position: absolute;
    left: -40px;
    bottom: -50px;

    @include media('>=desktopLarge') {
        left: -50px;
        bottom: -70px;
    }
}

.reservation-block-2-img-2-decor {
    position: absolute;
    top: -50px;
    right: -80px;

    @include media('>=desktopLarge') {
        top: -50px;
        right: -170px;
    }
}

.reservation-block-2-link-wrapper {
    text-align: center;
    margin: 0 20px 50px;

    @include media('>=desktop') {
        margin: 20px 20px 0;
    }
}

.reservation-block-2-btn-text {
    font-size: 25px;
    line-height: 44px;
    letter-spacing: 1.25px;

    @include media('>=desktop') {
        text-align: center;
        font-size: 36px;
        line-height: 60px;
        letter-spacing: 1.8px;
        color: $marine;
        border-color: $marine;
    }
}
