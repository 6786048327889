// Content asset page
.content-asset-wrapper {
    margin: 50px 15px 90px;

    @include media('>=tablet') {
        margin: 40px 40px 100px;
    }

    @include media('>=desktop') {
        max-width: 846px;
        margin: 48px auto 130px;
    }
}

// FAQ-like list
.faq-main-title {
    text-align: center;
    color: $rounge;
    margin: 0 0 38px;

    @include media('>=desktop') {
        margin: 0 0 54px;
    }
}

.faq-info-title {
    position: relative;
    font-family: $GranvilleLight;
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0 35px 12px 0;
    margin: 0 0 20px;
    border-bottom: 1px solid $marine;
    cursor: pointer;

    @include media('>=desktop') {
        margin: 0 0 30px;
    }

    &.active {
        margin: 0 0 15px;
        overflow: hidden;
    }

    &::before {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 3px;
        content: '+';
        width: 15px;
        font-size: 35px;
        height: calc(100% - 12px);
        overflow: hidden;
    }

    &.active::before {
        content: '—';
    }
}

.faq-info-desc {
    display: none;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.95px;
    margin: 0 0 40px;

    @include media('>=desktop') {
        font-size: 17px;
        line-height: 22px;
        letter-spacing: 0.85px;
        margin: 0 0 30px;
    }

    &.active {
        display: block;
    }

    .faq-info-desc-h2 {
        font: inherit;
        font-family: $GaramondRegular;
        text-transform: none;

        @include media('>=desktop') {
            margin: 0;
            font-size: 22px;
            line-height: 25px;
            letter-spacing: 1.1px;
        }
    }
}

// Cookies optin section
.optin-section {
    margin: 0 0 50px;
}

.optin-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 8px;

    @include media('>=tablet') {
        flex-direction: row;
        align-items: stretch;
        margin: 0 0 25px;
    }
}

.optin-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 215px;
    padding: 15px;
    margin: 0 12px 12px;
    border: 1px solid $marine60;
    text-align: center;

    @include media('>=tablet') {
        margin: 0 10px;
    }

    @include media('>=desktop') {
        width: 245px;
        padding: 20px 15px;
    }
}

.optin-item-title {
    font: 18px / 20px $GranvilleLight;
    letter-spacing: 1.6px;
    color: $marine;
    margin: 0 0 15px;

    @include media('>=desktop') {
        margin: 0 0 20px;
    }
}

.optin-item-option {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.optin-item-radio {
    padding: 0 10px;
}

.optin-btn-wrapper {
    text-align: center;
    margin: 16px 0 0;
}
