// =================================
//    Header
// =================================

.header-panel {
    position: relative;
    z-index: 2;  // above the 1st section
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 17px 15px;

    @include media('>=desktop') {
        padding: 25px 20px;
    }

    @include media('>=desktopLarge') {
        padding: 25px 40px;
    }
}

.header-white-panel {
    .svgburger {
        .st0 {
            stroke: $white;
        }
    }

    .logosvg {
        .st0 {
            fill: $white;
        }
    }

    .bookyourstaysvg {
        .st0 {
            fill: $white;
        }
    }
}

.header-left-side {
    width: 27px;

    @include media('>=desktop') {
        width: 34px;
    }
}

.header-right-side {
    width: 70px;
    height: 70px;
}


.header-burger {
    width: 27px;
    height: 19px;
    margin: 25px 0 0;

    @include media('>=desktop') {
        width: 34px;
        height: 22px;
        margin-top: 24px;
    }

    .svgburger {
        @include media('>=desktop') {
            width: 34px;
            height: 22px;
        }
    }
}

.header-logo {
    height: 61px;

    @include media('>=desktop') {
        height: 70px;
    }

    .logosvg {
        margin-top: 4px;
        width: 144px;
        height: 61px;

        @include media('>=desktop') {
            margin-top: 2px;
            width: 155px;
            height: 66px;
        }
    }
}

.menu-active {
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

.navigation-menu {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: -100%;
    bottom: 0;
    visibility: hidden;
    padding: 17px 15px 60px;
    background-color: $glacier;

    @include media('>=desktop') {
        padding: 35px 20px 100px;
    }

    @include media('>=desktopLarge') {
        padding: 35px 40px 100px;
    }

    &.show {
        left: 0;
        bottom: 0;
        overflow-y: scroll;
        z-index: 20001;
        pointer-events: auto;
        visibility: visible;
    }
}

.nav-top-panel {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.nav-top-panel-side {
    width: 70px;
    height: 70px;
}

.nav-top-panel-close {
    width: 21px;
    height: 21px;
    margin: 15px 0 0;

    .closesvg {
        width: 21px;
        height: 21px;
    }
}

.nav-top-panel-logo {
    height: 61px;

    @include media('>=desktop') {
        height: 66px;
    }

    .logosvg {
        width: 144px;
        height: 61px;

        @include media('>=desktop') {
            width: 155px;
            height: 66px;
        }
    }
}

.header-locales {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    margin: 50px 0;
    font-family: $GaramondRegular;
    font-size: 18px;
    line-height: 31px;

    @include media('>=desktop') {
        display: block;
        margin: 0 0 45px;
    }
}

.header-locales-separator {
    margin: 0 6px;
}

.header-locale-link {
    font-family: $GaramondRegular;
    font-size: 18px;
    line-height: 31px;

    &.active {
        color: $rounge;
        font-weight: bold;
        border-bottom: 1px solid $rounge;
    }

    &:hover {
        text-decoration: none;
    }

    @include media('>=desktop') {
        display: inline-block;
    }
}

.header-locale-link-fr {
    @include media('>=desktop') {
        margin: 0 0 8px;
    }
}

.nav-main-link-list {
    text-align: center;
    margin: 0 0 60px;

    @include media('>=desktop') {
        margin: 0 0 70px;
    }
}

.nav-main-link-li {
    margin: 0 0 30px;

    @include media('>=desktop') {
        margin: 0 0 20px;
    }
}

.nav-main-link {
    position: relative;
    font-family: $GranvilleLight;
    font-size: 35px;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    @include media('>=desktop') {
        font-size: 50px;
        line-height: 65px;
    }

    &:hover {
        font-family: $GranvilleLight;
        text-decoration: none;
    }
}

.nav-welcome-link:hover {
    @include media('>=desktop') {
        &::after {
            content: '';
            @include mobile-first-icon('welcome');
            display: inline-block;
            position: absolute;
            top: -30px;
            right: -160px;
        }
    }

    &::after {
        @include media('retina2x') {
            right: -320px;
        }
    }
}

.nav-plage-link:hover {
    @include media('>=desktop') {
        &::before {
            content: '';
            @include mobile-first-icon('laplage');
            display: inline-block;
            position: absolute;
            top: -80px;
            left: -146px;
        }
    }
}

.nav-restaurant-link:hover {
    @include media('>=desktop') {
        &::after {
            content: '';
            @include mobile-first-icon('restaurant');
            display: inline-block;
            position: absolute;
            top: -60px;
            right: -150px;
        }
    }

    &::after {
        @include media('retina2x') {
            right: -300px;
        }
    }
}

.nav-events-link:hover {
    @include media('>=desktop') {
        &::before {
            content: '';
            @include mobile-first-icon('events');
            display: inline-block;
            position: absolute;
            top: -50px;
            left: -160px;
        }
    }
}

.nav-privatization-link:hover {
    @include media('>=desktop') {
        &::after {
            content: '';
            @include mobile-first-icon('privatization');
            display: inline-block;
            position: absolute;
            top: -40px;
            right: -190px;
        }
    }

    &::after {
        @include media('retina2x') {
            right: -380px;
        }
    }
}

.nav-gallery-link:hover {
    @include media('>=desktop') {
        &::before {
            content: '';
            @include mobile-first-icon('gallery');
            display: inline-block;
            position: absolute;
            top: -30px;
            left: -145px;
        }
    }
}

.nav-bottom-link-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.nav-bottom-link-li {
    padding: 0 25px 0 0;

    &:last-child {
        padding: 0;
    }

    @include media('>=desktop') {
        padding: 0 48px 0 0;

        &:last-child {
            padding: 0;
        }
    }
}

.nav-bottom-link {
    font-size: 22px;
    line-height: 31px;
    letter-spacing: 0.05em;
    padding: 0 0 3px;

    @include media('>=desktop') {
        font-size: 25px;
    }
}

// ECM20-127 - Cookie Banner - CTA click mandatory
.cookie-dialog-content {
    text-align: center;
    font-size: 14px;

    .content-asset {
        color: $blue;
        margin: 0 0 30px;

        @include media('>=desktop') {
            margin: 0 0 40px;
        }
    }

    .cookie-buttons {
        justify-content: center;
    }

    .cookie-button {
        margin-top: 0;
    }

    .cookie-refuse {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }
}

// GDPR-22 - GDPR - Consent alert when privacy policy changes
.cookie-update-banner {
    text-align: center;
    padding: 5px;
    font-size: 12px;
    min-height: 110px;
    border: 2px solid $blue;
    background-color: $white;
    z-index: 20000;
    position: relative;

    .cookie-update-content {
        margin: 0 auto;
        width: 95%;

        @include media('>=tablet') {
            width: 85%;
        }
    }

    .content-asset {
        margin: 0 15px;
    }

    .page-scrolled & {
        display: none;
    }

    @include media('>=tablet') {
        min-height: 90px;
    }

    @include media('>=desktop') {
        min-height: 70px;
    }
}

.cookie-button {
    width: 140px;
    margin-top: 10px;
    font-weight: normal;
    vertical-align: top;

    +.cookie-button {
        margin-left: 5px;
    }

    &.gl_button {
        height: 30px;
        line-height: 30px;
        padding: 0;
        font-size: 11px;
    }

    &.gl_btn-blue {
        border: 1px solid $blue;
    }

    &.edit-consent {
        color: $black;
        font-weight: bold;
    }

    &.more-info {
        font-weight: bold;
    }
}

.cookie-close {
    position: absolute;
    right: 3px;
    top: 0;
    padding: 5px 10px 10px;
}

// prehome header
.prehome-header-panel {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 34px 16px 20px 24px;

    @include media('>=desktop') {
        align-items: center;
        padding: 34px 34px 34px 40px;
    }
}

.prehome-header-white-panel {
    .logosvg {
        .st0 {
            fill: $white;
        }
    }
}

.prehome-header-left-side {
    width: 155px;
}

.prehome-header-logo {
    .logosvg {
        width: 155px;
        height: 66px;
    }
}

.prehome-header-locales {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    font-family: $GaramondRegular;
    font-size: 18px;
    line-height: 31px;
    padding: 0 0 0 15px;

    @include media('>=desktop') {
        align-items: center;
    }
}

.prehome-header-locale-link {
    font-family: $GaramondRegular;
    font-size: 18px;
    line-height: 31px;
    color: $beige;
    margin: 0 14px 0 0;
    text-align: right;
    border-bottom: 1px solid transparent;

    &:last-child {
        margin: 0;
    }

    &.active {
        color: $rounge;
        font-weight: bold;
        border-color: $rounge;
    }

    &:hover {
        text-decoration: none;
    }

    &:hover:not(.active) {
        color: inherit;
    }
}
