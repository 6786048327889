.restaurant-block-2 {
    margin: 0 0 56px;

    @include media('>=desktop') {
        margin: 0 auto 82px;
    }
}

.restaurant-block-2-carousel-wrapper {
    overflow: hidden;
    margin: 0 0 35px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.restaurant-block-2-carousel-tile {
    padding: 30px 35px;

    @include media('>=desktop') {
        padding: 50px 40px;
    }

    &:nth-child(even) {
        .imageforcarousel_restaurant-img {
            @include media('<desktop') {
                margin: 0 0 0 auto;
            }
        }
    }
}

.restaurant-block-2-carousel-tile:first-child {
    @include media('>=desktop') {
        padding: 100px 40px 50px 137px;
    }

    .imageforcarousel-restaurant-icon-wrapper {
        transform: rotate(50deg);
        top: -65px;
        left: -60px;

        @include media('>=desktop') {
            top: -120px;
            left: -120px;
        }
    }
}

.restaurant-block-2-carousel-tile:nth-child(2) {
    .imageforcarousel-restaurant-icon-wrapper {
        bottom: -30px;
        right: -40px;

        @include media('>=desktop') {
            bottom: -60px;
            right: -80px;
        }
    }
}

.restaurant-block-2-carousel-tile:nth-child(3) {
    @include media('>=desktop') {
        padding: 70px 40px 50px;
    }

    .imageforcarousel-restaurant-icon-wrapper {
        top: -40px;
        left: -50px;
        transform: scaleX(-1);

        @include media('>=desktop') {
            top: -80px;
            left: -60px;
        }
    }
}

.restaurant-block-2-anchor-wrapper {
    text-align: center;
}
