.event-highlight-title {
    margin: 0 0 12px;
}

.event-highlight-text {
    text-align: center;
    padding: 15px;
}

.event-highlight-desc {
    font-family: $GranvilleLight;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
