.hp-block-2 {
    text-align: center;
}

// subblock 1
.hp-block-2-subblock-1 {
    text-align: center;
    margin: 50px 0;

    @include media('>=desktop') {
        position: relative;
        margin: 75px 0 47px;
    }
}

.hp-block-2-subblock-1-content {
    margin: 0 20px;

    @include media('>=desktop') {
        max-width: 630px;
        margin: 0 auto;
    }
}

.hp-block-2-subblock-1-title {
    margin: 0 0 25px;
}

.hp-block-2-subblock-1-desc {
    margin: 0 0 20px;

    @include media('>=desktop') {
        font-size: 32px;
        line-height: 41px;
        margin: 0 0 37px;
    }
}

.hp-block-2-subblock-1-links {
    margin: 20px 0 0;
    text-align: center;
    @include media('>=desktop') {
        display: flex;
        justify-content: center;
    }
}

.hp-block-2-subblock-1-links-gap {
    .hp-block-2-subblock-1-action-wrapper:first-child {
        @include media('>=desktop') {
            padding: 0 48px 0 0;
        }
    }
}

.hp-block-2-subblock-1-action-wrapper {
    margin: 10px 0 0;
}

.hp-block-2-subblock-1-image {
    @include media('>=desktop') {
        max-width: calc(50vw - 315px);
    }
}

.hp-block-2-subblock-1-image-1 {
    @include media('>=desktop') {
        position: absolute;
        top: -30px;
        left: 30px;
    }
}

.hp-block-2-subblock-1-image-2 {
    @include media('>=desktop') {
        position: absolute;
        bottom: -50px;
        right: 30px;
    }
}

// subblock 2 & 3
.hp-block-2-row-subblock {
    margin: 50px 0;
    padding: 0 15px;
    overflow: hidden;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 auto;
        padding: 45px 40px;
    }

    @include media('>=desktopLarge') {
        padding: 45px 87px;
    }
}

.hp-block-2-row-image {
    position: relative;
    flex-shrink: 0;
}

.hp-block-2-row-decor {
    position: absolute;
}

.hp-block-2-subblock-2-decor {
    right: -20%;
    bottom: -26%;
    transform: rotate(-10deg);
    width: 90%;

    @include media('>=desktop') {
        right: -60px;
        bottom: -50px;
        max-width: 73%;
        width: auto;
    }
}

.hp-block-2-row-svg {
    width: 100%;
}

.hp-block-2-subblock-3-image {
    padding-top: 23%;

    @include media('>=desktop') {
        padding-top: 134px;
    }
}

.hp-block-2-subblock-3-decor {
    width: 112%;
    top: 0;
    left: -7%;

    @include media('>=desktop') {
        width: 640px;
        top: 0;
        left: -60px;
    }
}

.hp-block-2-row-img {
    margin: 0 auto 37px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.hp-block-2-row-content {
    @include media('>=desktop') {
        flex-grow: 1;
    }

    &.left {
        @include media('>=desktop') {
            padding: 0 20px 0 0;
        }
    
        @include media('>=desktopLarge') {
            padding: 0 46px 0 0;
        }
    }

    &.right {
        @include media('>=desktop') {
            padding: 0 0 0 20px;
        }
    
        @include media('>=desktopLarge') {
            padding: 0 0 0 46px;
        }
    }
}

.hp-block-2-row-title {
    margin: 0 0 10px;
}


.hp-block-2-row-block-desc {
    margin: 0 0 20px;

    @include media('>=desktop') {
        margin: 0 0 30px;
    }
}

.hp-block-2-subblock-4-decor {
    right: -20%;
    bottom: -26%;
    transform: rotate(-10deg);
    width: 90%;

    @include media('>=desktop') {
        right: -60px;
        bottom: -50px;
        max-width: 73%;
        width: auto;
    }
}
