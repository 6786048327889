.hp-block-4 {
    margin: 55px 0 20px;

    @include media('>=desktop') {
        margin: 63px auto 20px;
    }
}

.hp-block-4-header {
    margin: 30px 15px;
    text-align: center;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row;
        text-align: left;
        align-items: center;
        margin: 30px 47px;
    }
}

.hp-block-4-header-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.hp-block-4-title {
    margin: 0 0 24px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.hp-block-4-desc {
    @include media('>=desktop') {
        padding: 0 0 0 100px;
    }
}

.hp-block-4-carousel-wrapper {
    overflow: hidden;
}

.hp-block-4-carousel:not(.slick-initialized) {
    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
    }

    .hp-block-4-carousel-tile {
        @include media('>=tablet') {
            flex: 0 0 25%;
            max-width: 25%;
        }
        
        @include media('>=desktop') {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }
}
