.ui-front {
    z-index: 100;
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// =================================
//    Checkbox
// =================================
.ui-checkboxradio-label:not(.ui-checkboxradio-radio-label) {
    position: relative;
    cursor: pointer;
    font: 16px / 20px $GaramondRegular;
    padding: 0 0 0 30px;
    display: inline-block;
    color: $marine;
    text-align: left;

    .ui-checkboxradio-icon-space {
        display: none;
    }

    .ui-icon-background {
        width: 14px;
        height: 14px;
        overflow: visible;
        background-color: $beige;
        border: 2px solid $beige;
        outline: 1px solid $marine;
        position: absolute;
        top: 3px;
        left: 1px;

        .gl_checkout-glacier & {
            background-color: $glacier;
            border: 2px solid $glacier;
        }
    }

    // active state: inner blue square
    &.ui-state-active {
        .ui-icon-background {
            background-color: $marine;
        }
    }

    // EADA-25 - Lack of focus indicators
    &.ui-state-focus {
        .ui-icon-background {
            outline-color: $blue-hover;
        }
    }
}

.ui-button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // Support: IE <= 11
    overflow: visible;
}

// =================================
//    Radio Button
// =================================
.ui-checkboxradio-radio-label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 30px;
    cursor: pointer;
    font: 16px / 20px $GaramondRegular;
    color: $marine;

    .ui-icon-background {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: visible;
        background-color: $beige;
        border: 3px solid $beige;
        outline: 1px solid $marine;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .ui-checkboxradio-icon-space {
        display: none;
    }

    &.ui-state-active {
        .ui-icon-background {
            background-color: $marine;
        }
    }

    // EADA-25 - Lack of focus indicators
    &.ui-state-focus {
        .ui-icon-background {
            outline-color: $blue-hover;
        }
    }
}

// =================================
//    General style for ui dialog
// =================================
.ui-dialog {
    background-color: $beige;
    padding: 15px;
    z-index: 30000;
    max-width: 95%;
    // to ignore body pointer-events: none; if mobile menu is opened
    pointer-events: auto;

    &.cookieDialog {
        position: fixed !important;
        top: 5px !important;
        left: 50% !important;
        transform: translateX(-50%);
        border: 2px solid $blue;
        min-width: calc(100% - 10px);
        max-width: calc(100% - 10px);
        padding: 30px 20px;

        @include media('>=desktop') {
            min-width: calc(100% - 10px);
            max-width: calc(100% - 10px);
        }

        .ui-dialog-titlebar {
            display: none;
        }

        .ui-dialog-content {
            min-height: auto;
        }
    }

    &.reservation-modal {
        .ui-dialog-titlebar {
            display: none;
        }
    }
}

// Overlay
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: $grey;
    // to ignore body pointer-events: none; if mobile menu is opened
    pointer-events: auto;
}
