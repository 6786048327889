.privatisation-block-6 {
    padding: 45px 15px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        padding: 75px 30px;
    }

    @include media('>=desktopLarge') {
        padding: 75px 95px;
    }
}

.privatisation-block-6-img-wrapper-desktop {
    @include media('>=desktop') {
        padding: 0 80px 0 0;
    }

    @include media('>=desktopLarge') {
        padding: 0 100px 0 0;
        flex-grow: 1;
    }
}

.privatisation-block-6-content {
    margin: 0 0 60px;

    @include media('>=desktop') {
        margin: 0;
    }

    @include media('>=desktopLarge') {
        flex: 0 0 587px;
        max-width: 587px;
    }
}

.privatisation-block-6-title {
    text-align: center;
    font-size: 35px;
    line-height: 40px;
    letter-spacing: 1.75px;
    margin: 0 0 20px;

    @include media('>=desktop') {
        font-size: 36px;
        line-height: 50px;
        letter-spacing: 1.8px;
        margin: 0 0 60px;
    }
}
