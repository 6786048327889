.beach_list-component {
    padding: 25px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 50px;
    }
}

.beach_list-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.beach_list-beaches {
    @include media('>=desktop') {
        padding: 0 50px 0 0;
    }

    .beach-link.active {
        color: $glacier;
        border-color: $glacier;
        text-decoration: none;
    }
}

.beach_list-beach-info {
    @include media('>=desktop') {
        padding: 0 30px 0 0;
    }

    @include media('>=desktopLarge') {
        padding: 0 100px 0 0;
    }

    .beach-img-content {
        display: none;
        margin: 0;

        &.active {
            display: inline-block;
        }
    }

    .beach-img-wrapper {
        padding: 0 60px 0 0;

        @include media('>=desktop') {
            padding: 0;
        }
    }

    .beach-subtitle {
        @include media('>=desktop') {
            text-align: center;
        }
    }
}

.beach_list-title {
    font-family: $GaramondItalic;
    font-size: 21px;
    text-transform: none;
    color: $white;
    margin: 0 0 40px;

    @include media('>=desktop') {
        font-size: 25px;
        margin: 0 0 55px;
    }
}
