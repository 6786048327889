.privatisation-block-2 {
    background-color: $glacier;
    padding: 50px 15px 55px;
    text-align: center;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        padding: 70px 68px 55px;
        text-align: left;
    }
}

.privatisation-block-2-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 20px;
    }
}

.privatisation-block-2-title {
    font-size: 35px;
    line-height: 40px;
    letter-spacing: 1.75px;
    margin: 0 0 20px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.privatisation-block-2-desc {
    margin: 0 0 30px;
}
