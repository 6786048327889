// Do not edit this file, everything will be removed after running gulp tasks

$sprite: (
      'events': (
        offset-x: 0px,
        offset-y: -395px,
        width: 152px,
        height: 134px,
        total-width: 187px,
        total-height: 911px,
        image: '../../images_active/sprites.png'
      ),
      'gallery': (
        offset-x: 0px,
        offset-y: 0px,
        width: 123px,
        height: 104px,
        total-width: 187px,
        total-height: 911px,
        image: '../../images_active/sprites.png'
      ),
      'laplage': (
        offset-x: 0px,
        offset-y: -549px,
        width: 146px,
        height: 166px,
        total-width: 187px,
        total-height: 911px,
        image: '../../images_active/sprites.png'
      ),
      'privatization': (
        offset-x: 0px,
        offset-y: -252px,
        width: 187px,
        height: 123px,
        total-width: 187px,
        total-height: 911px,
        image: '../../images_active/sprites.png'
      ),
      'restaurant': (
        offset-x: 0px,
        offset-y: -735px,
        width: 162px,
        height: 176px,
        total-width: 187px,
        total-height: 911px,
        image: '../../images_active/sprites.png'
      ),
      'welcome': (
        offset-x: 0px,
        offset-y: -124px,
        width: 146px,
        height: 108px,
        total-width: 187px,
        total-height: 911px,
        image: '../../images_active/sprites.png'
      ),
      'events_2x': (
        offset-x: 0px,
        offset-y: -790px,
        width: 304px,
        height: 268px,
        total-width: 374px,
        total-height: 1822px,
        background-size: 374px,
        image: '../../images_active/sprites_2x.png'
      ),
      'gallery_2x': (
        offset-x: 0px,
        offset-y: 0px,
        width: 246px,
        height: 208px,
        total-width: 374px,
        total-height: 1822px,
        background-size: 374px,
        image: '../../images_active/sprites_2x.png'
      ),
      'laplage_2x': (
        offset-x: 0px,
        offset-y: -1098px,
        width: 292px,
        height: 332px,
        total-width: 374px,
        total-height: 1822px,
        background-size: 374px,
        image: '../../images_active/sprites_2x.png'
      ),
      'privatization_2x': (
        offset-x: 0px,
        offset-y: -504px,
        width: 374px,
        height: 246px,
        total-width: 374px,
        total-height: 1822px,
        background-size: 374px,
        image: '../../images_active/sprites_2x.png'
      ),
      'restaurant_2x': (
        offset-x: 0px,
        offset-y: -1470px,
        width: 324px,
        height: 352px,
        total-width: 374px,
        total-height: 1822px,
        background-size: 374px,
        image: '../../images_active/sprites_2x.png'
      ),
      'welcome_2x': (
        offset-x: 0px,
        offset-y: -248px,
        width: 292px,
        height: 216px,
        total-width: 374px,
        total-height: 1822px,
        background-size: 374px,
        image: '../../images_active/sprites_2x.png'
      ),
);




@mixin sprite-size($image) {
  background-size: map-get(map-get($sprite, $image), 'total-width') map-get(map-get($sprite, $image), 'total-height');
}

@mixin sprite-width($image) {
  width: map-get(map-get($sprite, $image), 'width');
}

@mixin sprite-height($image) {
  height: map-get(map-get($sprite, $image), 'height');
}

@mixin sprite-position($image) {
  background-position: map-get(map-get($sprite, $image), 'offset-x') map-get(map-get($sprite, $image), 'offset-y');
}

@mixin sprite-image($image) {
  background-image: url(map-get(map-get($sprite, $image), 'image'));
}

// useful functions
@function get-sprite-width($image) {
  @return map-get(map-get($sprite, $image), 'width');
}

@function get-sprite-height($image) {
  @return map-get(map-get($sprite, $image), 'height');
}

// Generate certain image sprite
// Arguments
// - $image : sprite image name
// - $showWidth : adds width option to sprite.
// - $showHeight : adds height option to sprite.
@mixin sprite($image, $showWidth: true, $showHeight : true) {
  @include sprite-image($image);
  @include sprite-position($image);
  background-repeat: no-repeat;

  @if $showWidth {
    @include sprite-width($image);
  }

  @if $showHeight {
    @include sprite-height($image);
  }

}

// Useful to generate all sprites - Optimized for vertical aligned sprites
// Arguments
// - $showWidth : adds width option to sprite.
// - $showHeight : adds height option to sprite.
@mixin sprites( $showWidth : false, $showHeight : true ) {
  @each $icon-name, $icon-properties in $sprite {
    .#{$icon-name} { @include sprite( $icon-name, $showWidth, $showHeight ); }
  }
}



// Generate all sprites by default
// Add `$generate-all-sprites : false;` into your settings to skip this

$generate-all-sprites : false !default;
@if $generate-all-sprites {
  @include sprites;
}
