.quote-form-row {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 -8px;

    @include media('>=desktop') {
        margin: 0 -17px;
    }
}

.quote-form-col {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 8px;

    @include media('>=desktop') {
        padding: 0 17px;
    }
}

.quote-submit-wrapper {
    text-align: center;
    margin: 16px 0 0;
}
