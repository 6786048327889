.pd-rounge-content {
    @include media('>=desktop') {
        margin: 55px auto 75px;
    }
}

.pd-rounge-header {
    color: $rounge;
    margin: 50px 15px 45px;
    text-align: center;

    @include media('>=desktop') {
        margin: 0 85px;
    }
}

.pd-rounge-header-title {
    color: $rounge;
    font-size: 45px;
    line-height: 55px;
    letter-spacing: 2.25px;
    margin: 0 0 15px;

    @include media('>=desktop') {
        font-size: 100px;
        line-height: 100px;
        letter-spacing: 3px;
        margin: 0 0 30px;
    }
}

.pd-rounge-header-weather {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    text-align: center;
    font-family: $GranvilleLight;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin: 0 -10px;
    color: $rounge;

    @include media('>=tablet') {
        justify-content: center;
        margin: 0;
    }

    @include media('>=desktop') {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.9px;
    }
}

.pd-rounge-header-weather-item {
    padding: 0 10px;
}
