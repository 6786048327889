.event-first-block-highlight2 {
    position: relative;
    background-color: $white;
    text-align: center;
    padding: 40px 20px 50px;
}

.event-highlight2-title {
    margin: 0 0 15px;

    @include media('>=desktop') {
        margin: 0 0 20px;
    }
}

.event-highlight2-desc {
    font-family: $GranvilleLight;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 15px;
}

.event-highlight2-icon-wrapper {
    position: absolute;
    top: 0;
    left: 0;
}
