.reservation-block-3 {
    background-color: $glacier;
}

.reservation-block-3-content {
    padding: 56px 15px 65px;
    margin: 0 0 70px;

    @include media('>=desktop') {
        max-width: 626px;
        margin: 0 auto 70px;
        padding: 50px 0 60px;
    }
}

.reservation-block-3-desc {
    text-align: center;

    @include media('>=desktop') {
        text-align: left;
    }
}

.reservation-block-3-desc-col-title {
    @include media('>=desktop') {
        flex: 0 0 260px;
        max-width: 260px;
    }
}

.reservation-block-3-desc-col-content {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.1px;

    @include media('>=desktop') {
        padding: 0 0 0 20px;
    }
}

.reservation-block-3-desc-row {
    margin: 0 0 25px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
    }
}

.reservation-block-3-desc-subtitle {
    margin: 0 0 16px;
}

.reservation-block-3-list {
    font-family: $GaramondRegular;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 1.1px;
}

.reservation-block-3-link-wrapper {
    text-align: center;
}

.reservation-block-3-schedule {
    @include media('>=desktop') {
        line-height: 50px;
    }
}
