.restaurant-block-3 {
    position: relative;
}

.restaurant-block-3-content {
    @include absolute-center;
    background-color: $beige;
    padding: 57px;
    width: calc(100% - 82px);
    text-align: center;

    @include media('>=desktop') {
        padding: 74px;
        width: 650px;
    }
}

.restaurant-block-3-text {
    font-family: $GranvilleLight;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    margin: 0 0 24px;

    @include media('>=desktop') {
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 1px;
    }
}
