@mixin circle-letters($step: 12deg, $lettersNumber: 10) {
    // even letters number case
    @if $lettersNumber % 2 == 0 {
        $halfLettersNumber: round(calc($lettersNumber / 2));
        $stepOffset: $step;

        // place the first half letters left from the center
        @for $i from 1 through $halfLettersNumber {
            .vp-circle-title-letter-#{$halfLettersNumber + 1 - $i} {
                transform: rotate(calc($stepOffset / -2) - ($i - 1) * $step);
            }
        }

        // Place the second half letters right from the center
        @for $i from 1 through $halfLettersNumber {
            .vp-circle-title-letter-#{$i + $halfLettersNumber} {
                transform: rotate(calc($stepOffset / 2) + ($i - 1) * $step);
            }
        }
    } @else {
        // odd letters number case

        // exclude center letter from odd letters number
        $excludeHalfLettersNumber: round(calc($lettersNumber / 2)) - 1;

        // place the first half letters left from the center letter with letter half-width offset
        @for $i from 1 through $excludeHalfLettersNumber {
            .vp-circle-title-letter-#{$excludeHalfLettersNumber + 1 - $i} {
                transform: rotate(-$i * $step);
            }
        }

        // Place the second half letters right from the center
        @for $i from 1 through $excludeHalfLettersNumber {
            .vp-circle-title-letter-#{$i + 1 + $excludeHalfLettersNumber} {
                transform: rotate($i * $step);
            }
        }
    }
}

@mixin circle-text($prefix: '', $isLetterGenerated: 'false', $mobileFontSize: 50px, $mobileLetterWidth: 50, $lettersNumber: 10) {
    // Deg offset calculation:
    // 1. quarter circle length = 0.5 * pi * radius ( <= circle length = 2 * pi * radius)
    //    circle radius to put letter on it (based on provided lobsters image proportion):
    //    - mobile: radius = calc(50vw * 0.72) ideally,
    //      but (vw & deg) for calculation don't work
    //      and (100vw / 2 / 1vw) doesn't real-time px calculation (= 50 but not in px values), so => calc(480px / 2 * 0.72) proximately
    //    - tablet: radius = calc(50vw * 0.72) ideally, but ... so => calc(1024px / 2 * 0.72) proximately
    //    - desktop: radius = calc(50vw * 0.72)
    //    - desktopLarge: radius = calc(1358px / 2 * 0.75)
    // 2. Amount of letters in quarter circle = (quarter circle length) / letter width (see width style value)
    //    - mobile: width = 50px or from $mobileLetterWidth
    //    - desktop: width = 60px
    //    - desktopLarge: width = 90px
    // 3. Deg offset per letter in quarter circle = 360 / 4 / (amount of letters in quarter circle)

    $radiusMobile: calc(480px / 2 / 1px);  // convert into number (np px to use for calculation)
    $radiusTabletDesktop: calc(1024px / 2 / 1px);  // convert into number
    $radiusDesktopLarge: calc(1358px / 2 / 1px);  // convert into number

    $degMobile: calc((90 / ((0.5 * 3.14 * ($radiusMobile * 0.72)) / $mobileLetterWidth)) * 1deg);
    $degTabletDesktop: calc((90 / ((0.5 * 3.14 * ($radiusTabletDesktop * 0.72)) / 60)) * 1deg);
    $degDesktopLarge: calc(90 / ((0.5 * 3.14 * ($radiusDesktopLarge * 0.75) / 90)) * 1deg);

    // push deg values into css var to used in css from isml if $isLetterGenerated == false
    @if $isLetterGenerated == 'false' {
        $customProperties: (
            degMobile#{$prefix}: #{$degMobile},
            degTabletDesktop#{$prefix}: #{$degTabletDesktop},
            degDesktopLarge#{$prefix}: #{$degDesktopLarge}
        );

        .vp-circle-text#{$prefix} {
            @each $property, $value in $customProperties {
                #{'--' + $property}: #{$value};
            }
        }
    }

    .vp-circle-text {
        position: absolute;
        left: 0;
        width: 100%;
        top: calc(100vw * 0.05);

        @include media('>=tablet') {
            top: calc(100vw * 0.05);
        }

        @include media('>=desktopLarge') {
            top: calc(1358px * 0.03);
        }
    }

    .vp-circle-title {
        position: relative;
        width: calc(50vw +  #{calc($mobileLetterWidth / 2)}px); // start circle text from screen middle + letter half-width (50 / 2)
        margin: 0 0 0 auto;
        text-align: center;
        // uncomment to understand rotate start
        // background-color: $white;
        // height: 50px;
        font-size: $mobileFontSize;
        line-height: normal;

        @if $isLetterGenerated == 'true' {
            @include circle-letters($degMobile, $lettersNumber);
        }

        @include media('>=tablet') {
            width: calc(50vw + 30px); // start circle text from screen middle + letter half-width (60 / 2)

            @if $isLetterGenerated == 'true' {
                @include circle-letters($degTabletDesktop, $lettersNumber);
            }
        }

        @include media('>=desktopLarge') {
            width: calc(1358px / 2 + 45px); // start circle text from screen middle + letter half-width (90 / 2)
            font-size: 100px;

            @if $isLetterGenerated == 'true' {
                @include circle-letters($degDesktopLarge, $lettersNumber);
            }
        }
    }

    // mobile to be fixed
    .vp-circle-title-letter {
        width: #{$mobileLetterWidth}px; // letter width
        height: calc(50vw - 20px); // circle radius to put letter on it
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: bottom center;

        @include media('>=tablet') {
            width: 60px; // letter width
            height: calc(50vw * 0.72); // circle radius to put letter on it
        }

        @include media('>=desktopLarge') {
            width: 90px; // letter width
            height: calc(1358px / 2 * 0.72); // circle radius to put letter on it
        }
    }
}
