.reservation-modal {
    position: fixed !important;
    top: calc(50% - 460px / 2) !important;
    left: calc(50% - 350px / 2) !important;
    background: $beige;
    padding: 30px 5px 10px;
    border-radius: 2px;

    @include media('>=desktop') {
        top: calc(50% - 540px / 2) !important;
        left: calc(50% - 840px / 2) !important;
        padding: 20px 40px;
        border-radius: 10px;
    }

    iframe {
        width: 340px;
        height: 480px;

        @include media('>=desktop') {
            width: 800px;
            height: 500px;
        }
    }

    .reservation-close {
        position: absolute;
        top: 0;
        right: 0;
        color: $blue;
        padding: 5px 10px;

        .closesvg {
            width: 20px;
            height: 20px;
        }
    }
}
