.imageforcarousel-restaurant-component {
    position: relative;
}

.imageforcarousel-restaurant-icon-wrapper {
    position: absolute;

    &.top {
        top: 0;
        left: 0;
    }

    &.bottom {
        right: 0;
        bottom: 0;
    }
}
