.contact-block-3 {
    background-color: $glacier;

    @include media('>=desktop') {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.contact-block-3-content {
    padding: 40px 15px 52px;

    @include media('>=desktop') {
        padding: 40px;
    }
}

.contact-block-3-col {
    @include media('>=desktop') {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

.contact-block-3-img-wrapper {
    @include center-image;

    @include media('>=desktop') {
        justify-content: flex-end;
    }
}

.contact-block-3-desc {
    text-align: center;

    @include media('>=desktop') {
        text-align: left;
    }
}

.contact-block-3-title {
    font-family: $GranvilleLight;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 1.8px;
    text-align: center;
    margin: 0 0 27px;

    @include media('>=desktop') {
        margin: 0 0 35px;
    }
}

.contact-block-3-desc-row {
    margin: 0 0 33px;

    @include media('>=desktop') {
        display: flex;
        flex-direction: row;
        margin: 0 0 40px;
        line-height: 25px;
    }

    // row with link
    &.contact-block-3-reservation-row {
        line-height: 31px;
    }
}

.contact-block-3-desc-row-title-wrapper {
    @include media('>=desktop') {
        flex: 0 0 220px;
        max-width: 220px;
    }
}

.contact-block-3-desc-row-title {
    text-align: center;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin: 0 0 16px;
    font-style: normal;

    @include media('>=desktop') {
        flex: 0 0 220px;
        max-width: 220px;
        text-align: left;
        margin: 0;
        font-size: 20px;
        line-height: inherit;
        letter-spacing: 1px;
    }
}

.contact-block-3-desc-row-text {
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.95px;
    font-style: normal;

    @include media('>=desktop') {
        line-height: inherit;
    }
}

.contact-block-3-address-link {
    text-transform: none;
}
