.lp-block-5 {
    background-color: $glacier;
}

.lp-block-5-wrapper {
    padding: 35px 0 55px;

    @include media('>=desktop') {
        padding: 75px 40px;
    }
}

.lp-block-5-title {
    text-align: center;
    margin: 0 15px 35px;

    @include media('>=desktop') {
        text-align: left;
        margin: 0 0 45px;
    }
}

.lp-block-5-content {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
    }
}

.lp-block-5-events {
    margin: 0 0 40px 15px;

    @include media('>=desktop') {
        flex-grow: 1;
        margin: 0;
        padding: 0 70px 0 0;
    }
}

.lp-block-5-events-carousel.slick-initialized {
    .lp-block-5-events-carousel-tile {
        width: 85vw;
    }
}

.lp-block-5-events-carousel:not(.slick-initialized) {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
    }

    .lp-block-5-events-carousel-tile {
        @include media('>=desktop') {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .lp-block-5-events-carousel-tile:first-child {
        @include media('>=desktop') {
            padding: 0 45px 0 0;
            margin: 40px 0 0;
        }
    }

    .lp-block-5-events-carousel-tile:nth-child(2) {
        @include media('>=desktop') {
            padding: 0 0 0 45px;
        }
    }
}

.lp-block-5-text {
    padding: 42px 0 0;
    border-top: 1px solid $marine;
    text-align: center;
    margin: 0 15px;

    @include media('>=desktop') {
        border: 0;
        padding: 0;
        text-align: left;
        flex: 0 0 252px;
        max-width: 252px;
        margin: 100px 0 0;
    }
}

.lp-block-5-subtitle {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 1.4px;
    margin: 0 0 30px;

    @include media('>=desktop') {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1.5px;
        margin: 0 0 26px;
    }
}

.lp-block-5-desc {
    margin: 0 0 28px;

    @include media('>=desktop') {
        margin: 0 0 16px;
    }
}

.hp-block-5-link {
    font-size: 19px;
    line-height: 31px;
}
