.contact-block-1 {
    text-align: center;
    margin: 50px 15px;

    @include media('>=desktop') {
        position: relative;
        margin: 45px auto 87px;
    }
}

.contact-block-1-content {
    @include media('>=desktop') {
        max-width: 740px;
        margin: 0 auto;
    }
}

.contact-block-1-desc {
    margin: 35px 0 0;

    @include media('>=desktop') {
        margin: 45px 0 0;
        font-size: 32px;
        line-height: 41px;
        letter-spacing: 1.6px;
    }
}

.contact-block-1-image-wrapper {
    @include media('>=desktop') {
        max-width: calc(50vw - 370px);
    }
}

.contact-block-1-image-1-wrapper {
    @include media('>=desktop') {
        position: absolute;
        top: -30px;
        left: 30px;
    }
}

.contact-block-1-image-2-wrapper {
    @include media('>=desktop') {
        position: absolute;
        bottom: -50px;
        right: 30px;
    }
}
