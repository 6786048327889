.event-first-block-tile {
    .event-title {
        color: $rounge;
        margin: 0 0 17px;
    }

    .event-schedule {
        margin: 0 0 7%;
    }

    .event-link {
        display: inline-block;
    }
}
