.ondineplage-content {
    position: relative;
    background-color: $white;
    margin: 0 15px;
    padding: 30px 20px 40px;
    text-align: center;
    margin-top: -90px;

    @include media('>=desktopLarge') {
        margin: 0 75px;
        margin-top: -90px;
    }
}

.ondineplage-title {
    margin: 0 0 5px;
}

.ondineplage-subtitle {
    font-size: 40px;
    line-height: 45px;
    margin: 0 0 12px;

    @include media('>=desktop') {
        font-size: 36px;
        line-height: 50px;
    }
}

.ondineplage-content-sticker-wrapper {
    position: absolute;
    display: inline-block;
    z-index: 1;
}

.hp-block-4-carousel-tile:nth-child(1) {
    .ondineplage-content-sticker-wrapper {
        top: -50px;
        right: -25px;

        @include media('>=desktop') {
            right: -35px;
        }
    }
}

.hp-block-4-carousel-tile:nth-child(2) {
    .ondineplage-content-sticker-wrapper {
        top: -50px;
        right: -25px;

        @include media('>=desktop') {
            right: -50px;
        }
    }
}

.hp-block-4-carousel-tile:nth-child(3) {
    .ondineplage-content-sticker-wrapper {
        top: -70px;
        right: -80px;
        transform: rotate(-45deg);
    }
}
