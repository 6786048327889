.events-block-2 {
    padding: 50px 0 0;

    @include media('>=desktop') {
        padding: 60px 0 0;
    }
}

.events-block-2-bg-content {
    position: relative;
}

.events-block-2-circle-text-wrapper {
    @include circle-text('-event', 'false', 42px, 40);
}

.events-block-2-top-content {
    .events-block-first-event {
        position: absolute;
        top: 50%;
        width: 70%;
        height: 50%;
        text-align: center;
        margin: 0 15%;

        @include media('>=tablet') {
            width: 34%;
            margin: 0 33%;
        }
    }

    .event-link {
        @include absolute-horizont-center;
        position: absolute;
        bottom: 0;

        @include media('>=tablet') {
            position: static;
            transform: none;
        }
    }
}

.events-block-2-desc-wrapper {
    font-family: $GaramondItalic;
    font-size: 19px;
    line-height: 25px;
    background-color: $glacier;
    padding: 35px 30px;

    @include media('>=desktop') {
        font-size: 30px;
        line-height: 35px;
        padding: 65px 35px;
    }
}

.events-block-2-desc {
    max-width: 310px;
    margin: 0 auto;
    text-align: center;

    @include media('>=desktop') {
        max-width: 768px;
    }
}

.events-block-2-bottom-content {
    padding: 40px 0;
    background-color: $glacier;

    @include media('>=desktop') {
        padding: 40px 0 0;
    }
}

.events-block-2-bottom-title {
    text-align: center;
    padding: 0 0 20px;

    @include media('>=tablet') {
        padding: 0 0 30px;
    }

    @include media('>=desktop') {
        text-align: left;
        padding: 0 40px;
    }
}

.events-block-2-carousel:not(.slick-initialized) {
    padding: 0 34px;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        padding: 0 60px;
    }

    @include media('>=desktop') {
        padding: 100px 40px 120px; // for stickers
    }

    .events-block-2-carousel-tile {
        flex: 0 0 100%;
        max-width: 100%;

        @include media('>=tablet') {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include media('>=desktop') {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }
}

.events-block-2-carousel.slick-initialized {
    .slick-list {
        @include media('>=desktop') {
            padding: 100px 40px 120px; // for stickers
        }
    }
}

.events-block-2-carousel-tile {
    padding: 0 7px;

    @include media('>=tablet') {
        padding: 0 15px;
    }

    @include media('>=desktop') {
        padding: 0 45px;
    }
}

.events-block-2-carousel {
    .slick-slide {
        .event-first-block-highlight2 {
            padding: 20px;
            margin: 20px 0 30px;
        }
    }

    .slick-current {
        .event-first-block-highlight2 {
            padding: 40px 20px 50px;
            margin: 0;

            @include media('>=desktop') {
                padding: 20px;
                margin: 20px 0 30px;
            }
        }
    }

    // 2nd active slide
    .slick-current + .slick-active {
        .event-first-block-highlight2 {
            @include media('>=desktop') {
                padding: 40px 20px 50px;
                margin: 0;
            }
        }
    }

    .slick-slide {
        .event-highlight2-icon-wrapper {
            display: none;
        }
    }

    // show stickers on active tiles only
    .slick-active {
        .event-highlight2-icon-wrapper {
            display: block;
        }
    }
}

.events-block-2-carousel-tile-1 {
    .event-highlight2-icon-wrapper {
        top: -70px;
        right: -90px;
        left: auto;
        transform: rotate(-4deg);
    }
}

.events-block-2-carousel-tile-2 {
    .event-highlight2-icon-wrapper {
        right: -80px;
        left: auto;
        top: auto;
        bottom: -80px;
    }
}

.events-block-2-carousel-tile-3 {
    .event-highlight2-icon-wrapper {
        top: -100px;
        left: -100px;
        transform: rotate(-120deg);
    }
}

.events-block-2-carousel-tile-4 {
    .event-highlight2-icon-wrapper {
        top: auto;
        bottom: -90px;
        right: -90px;
        left: auto;
        transform: rotate(-4deg);
    }
}
