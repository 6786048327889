.contact-block-2 {
    background-color: $glacier;

    @include media('>=desktop') {
        display: flex;
        flex-direction: row;
    }
}

.contact-block-2-content {
    padding: 35px 15px 48px;

    @include media('>=desktop') {
        padding: 55px 40px 35px;
    }
}

.contact-block-2-col {
    @include media('>=desktop') {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

.contact-block-2-img-wrapper {
    @include center-image;

    @include media('>=desktop') {
        justify-content: flex-start;
    }
}

.contact-block-2-title {
    font-family: $GranvilleLight;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 1.8px;
    text-align: center;
    margin: 0 0 25px;

    @include media('>=desktop') {
        font-size: 36px;
        line-height: 50px;
        margin: 0 0 35px;
    }
}
