.event-block-1 {
    position: relative;
    z-index: 1;  // below the header panel
    margin: -100px 0 0;

    @include media('>=desktop') {
        margin: -116px 0 0;
    }
}

.event-block-1-content {
    @include absolute-center;
    width: 100%;
    z-index: 2;
    color: $beige;
    text-align: center;
    max-width: calc(100vw - 40px);
    margin: 0 auto;
    background: $glacier;
    padding: 20px 15px;
    border-radius: 30px;

    @include media('>=desktop') {
        max-width: 700px;
        padding: 30px 20px;
    }
}

.event-block-1-title {
    margin: 0 0 14px;
    color: $rounge;

    @include media('>=desktop') {
        padding: 0 20px;
    }
}

.event-block-1-link-wrapper {
    margin: 15px 0 0;
}
