.blogarticle-title {
    color: $rounge;
    margin: 0 0 17px;
}

.blogarticle-schedule {
    margin: 0 0 7%;
}

.blogarticle-link {
    display: inline-block;
}
