.hp-block-1 {
    position: relative;
    z-index: 1;  // below the header panel
    margin: -100px 0 0;

    @include media('>=desktop') {
        margin: -120px 0 0;
    }
}

.hp-block-1-weather {
    @include absolute-center;
    width: 100%;
    z-index: 2;
    color: $white;
    text-align: center;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
}

.hp-block-1-weather-item {
    @include media('>=desktop') {
        padding: 0 30px;
    }
}

.hp-block-1-wind {
    @include media('>=desktop') {
        flex: 0 0 100%;
    }
}

.hp-block-1-carousel-wrapper .imagecarousel-img {
    width: 100vw;
}
