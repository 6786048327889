// =================================
//    Sprite suffix
// =================================
$sprite-suffix: '_2x';

// =================================
//    Breakpoints
// =================================
$phone: 320px;
$tablet: 768px;
$desktop: 1024px;
$desktopLarge: 1358px;
$desktopXL: 1920px;

// =================================
//    Colors
// =================================
$marine: #00315c;
$rounge: #f16141;
$white: #fff;
$black: #000;
$beige: #fffcf4;
$glacier: #d0e9e6;
$green: #057e70;
$blue: #0c315d;
$blue-hover: #1b65b1;
$marine60: #00315c99; // #00315C - 60%
$grey: #99abc1;

// VP loader colors
$loader-blue1: #2d50a7;
$loader-blue2: #355ec9;
$loader-blue3: #3d6deb;
$loader-blue4: #5286fa;
$loader-blue5: #73a1fb;
$loader-blue6: #c4d9fd;

// =================================
//    Fonts
// =================================
$GaramondRegular: GaramondRegular, Arial, sans-serif;
$GaramondItalic: GaramondItalic, Arial, sans-serif;
$GranvilleLight: GranvilleLight, Arial, sans-serif;
