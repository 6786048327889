html,
body {
    height: 100%;
    width: 100%;
}

body {
    font-family: $GaramondRegular;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.95px;

    @include media('>=desktop') {
        font-size: 22px;
        line-height: 25px;
        letter-spacing: 1.1px;
    }
}

* {
    box-sizing: border-box;
}

.gl_content {
    color: $marine;
    font-family: $GaramondRegular;
    background-color: $beige;
}

.gl_prehome-content {
    color: $beige;
    font-family: $GaramondRegular;
    background-color: $marine;
    min-height: 100vh;
}

button {
    background: 0;
    border: 0;
    padding: 0;
}

img {
    width: auto;
    max-width: 100%;
    vertical-align: top;
}

video {
    display: block;
}

.gl_wrapper-1358 {
    margin: 0 auto;
    max-width: 1358px;
}

.gl_wrapper-1920 {
    margin: 0 auto;
    max-width: 1920px;
}

.gl_flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

// =================================
//    Headings
// =================================
h1,
h2,
h3,
h4,
h5,
.fakeH1,
.fakeH2,
.fakeH3,
.fakeH4,
.fakeH5 {
    font-family: $GranvilleLight;
    margin: 0;
    padding: 0;
    color: $marine;
    text-transform: uppercase;
}

h1,
.fakeH1 {
    font-size: 52px;
    line-height: 52px;

    @include media('>=desktop') {
        font-size: 100px;
        line-height: 100px;
    }
}

h3,
.fakeH3 {
    font-size: 40px;
    line-height: 45px;
    letter-spacing: 2px;

    @include media('>=desktop') {
        font-size: 52px;
        line-height: 60px;
        letter-spacing: 3.25px;
    }

    @include media('>=desktopLarge') {
        font-size: 65px;
        line-height: 75px;
        letter-spacing: 3.25px;
    }
}

h4,
.fakeH4 {
    font-size: 28px;
    line-height: 36px;

    @include media('>=desktop') {
        font-size: 36px;
        line-height: 50px;
    }
}

h5,
.fakeH5 {
    font-size: 28px;
    line-height: 31px;
    letter-spacing: 1.4px;

    @include media('>=desktop') {
        font-size: 20px;
        line-height: 31px;
        letter-spacing: 1px;
    }
}

// =================================
//    Default link
// =================================
a,
.gl_fake-link {
    text-decoration: none;
    color: $marine;

    &:hover {
        color: $marine;
        text-decoration: underline;
    }
}

.gl_link {
    font-family: $GranvilleLight;
    font-size: 17px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0.85px;
    text-transform: uppercase;
    text-decoration: none;
    color: $rounge;
    padding: 0 0 6px;
    border-bottom: 1px solid $rounge;
    box-sizing: border-box;

    &:hover {
        color: $rounge;
        text-decoration: none;
    }

    @include media('>=desktop') {
        font-size: 19px;
        line-height: 31px;
        letter-spacing: 0.95px;
    }
}

.gl_link-wrapper {
    margin: 0 0 1px;
}

// =================================
//    Inline Description List
// =================================
dl {
    margin: 0;
}

.gl_dl-inline {
    dt,
    dd {
        display: inline;
    }

    dd {
        margin: 0;
    }

    dd::after {
        content: '';
        display: block;
    }
}

// =================================
//    Desktop only and mobile only
//   !important is used to overpower custom styles on elements
//   on which gl_desktop-only and gl_mobile-only will be used
// =================================
.gl_desktop-only {
    @include media('<desktop') {
        display: none !important;
    }
}

.gl_tablet-only {
    @include media('>=tablet') {
        display: none !important;
    }
}

.gl_mobile-only {
    @include media('>=desktop') {
        display: none !important;
    }
}

.gl_mobile-hidden-only {
    @include media('<tablet') {
        display: none !important;
    }
}

.gl_showOnPhone {
    @include media('>=tablet') {
        display: none !important;
    }
}

.gl_showOnTablet {
    display: none;

    @include media('>=tablet', '<desktop') {
        display: block !important;
    }
}

.gl_mobile-image-only {
    display: block !important;

    @include media('>=tablet') {
        display: none !important;
    }
}

.gl_tablet-image-only {
    display: none;

    @include media('>=tablet', '<desktop') {
        display: block !important;
    }
}

.gl_tabletanddesktop-image-only {
    display: none;

    @include media('>=tablet') {
        display: block !important;
    }
}

.gl_desktop-image-only {
    display: none;

    @include media('>=desktop', '<1200px') {
        display: block !important;
    }
}

.gl_desktoplarge-image-only {
    display: none;

    @include media('>=1200px', '<1500px') {
        display: block !important;
    }
}

.gl_desktopextralarge-image-only {
    display: none;

    @include media('>=1500px') {
        display: block !important;
    }
}

.gl_desktoplargeandextralarge-image-only {
    display: none;

    @include media('>=1200px') {
        display: block !important;
    }
}

// =================================
//    SVG
// =================================
.svgburger {
    display: block;
    width: 27px;
    height: 19px;

    .st0 {
        stroke: $marine;
        stroke-width: 1.5;
    }
}

.logosvg {
    display: block;
    width: 253px;
    height: 109px;

    .st0 {
        fill: $marine;
    }
}

.bookyourstaysvg {
    display: block;
    width: 70px;
    height: 70px;

    .st0 {
        fill: $marine;
    }
}

.closesvg {
    display: block;
    width: 23px;
    height: 23px;

    .st0 {
        stroke: $marine;
        stroke-width: 1.5;
    }
}

// Disc ul
.dl_list-disc {
    list-style: disc;
    list-style-position: outside;
    margin: 0 0 0 30px;
}

// Form elements
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="search"],
input[type="number"] {
    &:focus {
        outline-offset: 4px;
    }
}

.gl_field-row {
    position: relative;
}

.gl_ok {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 17px;
    line-height: 22px;
    height: 32px;
}

.gl_form-group {
    padding: 0 0 16px;

    @include media('>=desktop') {
        padding: 0 0 23px;
    }
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"] {
    width: 100%;
    background: transparent;
    border: 1px solid $marine60;
    font-family: $GaramondItalic;
    font-size: 17px;
    line-height: 22px;
    color: $marine;
    padding: 5px;
    height: 30px;

    @include media('>=desktop') {
        height: 32px;
    }

    .gl_has-error & {
        border-color: $rounge;
    }

    &.gl-input_ok {
        width: 100%;
        border: 0;
        border-bottom: 1px solid $marine;
        padding: 5px 30px 5px 5px;
    }
}

textarea {
    width: 100%;
    resize: none;
    background: transparent;
    border: 1px solid $marine60;
    font-family: $GaramondItalic;
    font-size: 17px;
    line-height: 22px;
    color: $marine;
    padding: 5px;
    height: 100px;
}

.form-success-message {
    text-align: center;
    font-family: $GranvilleLight;
    padding: 15px;
    color: $green;
}

.gl_label {
    font-family: $GranvilleLight;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    padding: 0 0 6px;
}

.gl_error-message,
.backend-error-message {
    display: block;
    font-family: $GaramondItalic;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.75px;
    color: $rounge;
}

.gl_has-error {
    .gl_error-message {
        padding: 5px 0 0;
    }
}

.gl_form-disabled {
    pointer-events: none;
    opacity: 0.5;
}

// SVG loader with css animation: change blue colors
.gl_loader-parent {
    position: relative;
}

@mixin loader-animation (
    $name: 'fillchange',
    $color1: $loader-blue1,
    $color2: $loader-blue2,
    $color3: $loader-blue3,
    $color4: $loader-blue4,
    $color5: $loader-blue5,
    $color6: $loader-blue6,
    $color7: $white
) {
    @keyframes #{$name} {
        0% {
            fill: $color1;
        }

        17% {
            fill: $color2;
        }

        33% {
            fill: $color3;
        }

        50% {
            fill: $color4;
        }

        67% {
            fill: $color5;
        }

        83% {
            fill: $color6;
        }

        100% {
            fill: $color7;
        }
    }

    animation-name: #{$name};
}

.gl_loader-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 10;

    .gl_loader {
        height: 100%;
    }

    .svgloader {
        width: 50px;
        height: 50px;
    }

    .st {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    .st0 {
        @include loader-animation;
    }

    .st1 {
        @include loader-animation(
            'fillchange-1',
            $loader-blue2,
            $loader-blue3,
            $loader-blue4,
            $loader-blue5,
            $loader-blue6,
            $white,
            $loader-blue1
        );
    }

    .st2 {
        @include loader-animation(
            'fillchange-2',
            $loader-blue3,
            $loader-blue4,
            $loader-blue5,
            $loader-blue6,
            $white,
            $loader-blue1,
            $loader-blue2,
        );
    }

    .st3 {
        @include loader-animation(
            'fillchange-3',
            $loader-blue4,
            $loader-blue5,
            $loader-blue6,
            $white,
            $loader-blue1,
            $loader-blue2,
            $loader-blue3,
        );
    }

    .st4 {
        @include loader-animation(
            'fillchange-4',
            $loader-blue5,
            $loader-blue6,
            $white,
            $loader-blue1,
            $loader-blue2,
            $loader-blue3,
            $loader-blue4,
        );
    }

    .st5 {
        @include loader-animation(
            'fillchange-5',
            $loader-blue6,
            $white,
            $loader-blue1,
            $loader-blue2,
            $loader-blue3,
            $loader-blue4,
            $loader-blue5,
        );
    }

    .st6 {
        @include loader-animation(
            'fillchange-6',
            $white,
            $loader-blue1,
            $loader-blue2,
            $loader-blue3,
            $loader-blue4,
            $loader-blue5,
            $loader-blue6,
        );
    }
}

// slick slider with equal height slides
.gl_slick-equal-slides {
    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;
    }
}

.invisible,
.visually-hidden {
    display: none;
}

// to be visible for screen readers only
.offscreen {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}

.offscreen_mobile-only {
    @include media('<desktop') {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }
}

.offscreen_mobileandtablet-only {
    @include media('<tablet') {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }
}

.offscreen_desktop-only {
    @include media('>=desktop') {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }
}

// =================================
//    global hide class
// =================================
.gl_hidden,
.hidden {
    display: none !important;
}
