.events-block-1 {
    position: relative;
    z-index: 1;  // below the header panel
    margin: -100px 0 0;

    @include media('>=desktop') {
        margin: -116px 0 0;
    }
}

.events-block-1-content {
    @include absolute-center;
    width: 100%;
    z-index: 2;
    color: $beige;
    text-align: center;
}

.events-block-1-title {
    color: $beige;
    margin: 0 0 14px;

    @include media('>=desktop') {
        padding: 0 20px;
    }
}

.events-block-1-weather {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-family: $GranvilleLight;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.6px;
    text-transform: uppercase;

    @include media('>=desktop') {
        font-size: 30px;
        line-height: 100px;
        letter-spacing: 0.9px;
    }
}

.events-block-1-weather-item {
    padding: 0 10px;

    @include media('>=desktop') {
        padding: 0 15px;
    }
}
